import { gql } from "@apollo/client";
import Stack from "atoms/Stack";
import { COLOR_BLACK, COLOR_PRIMARY, COLOR_WHITE } from "helpers/colors";
import { gaEvent, gaSetSharedEventParams } from "helpers/gtag";
import useData from "helpers/useData";
import useDOMEvent from "helpers/useDOMEvent";
import useGotoUrl from "helpers/useGotoUrl";
import { uiSettingsContext } from "providers/UISettingsProvider";
import React, { createContext, useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useWindowSize } from "react-use-size";

import Navbar from "./Navbar";

export const pageContext = createContext();

export function Page({ Component }) {
  const windowSize = useWindowSize();
  const params = useParams();
  const gotoUrl = useGotoUrl();
  const location = useLocation();

  useEffect(() => {
    gaEvent("page_view");
  }, [location.pathname]);

  const navbarLeft = windowSize.height < 600 && windowSize.width > windowSize.height;

  useDOMEvent("click", (event) => {
    if (event.defaultPrevented) return;
    const a = event.target.closest("a[href]");
    if (!a) return;
    let href = a.getAttribute("href");
    const target = a.getAttribute("target");

    event.stopPropagation();
    if (target && target !== "_self") return;
    if (event.ctrlKey || event.shiftKey || event.metaKey || event.altKey) return;

    event.preventDefault();
    if (!href || href.startsWith("#")) return;
    gotoUrl(href);
  });

  const { isDarkMode } = useContext(uiSettingsContext);
  const [data] = useData(gql`
    query Page {
      currentUser {
        id
        clubs {
          id
        }
      }
    }
  `);

  gaSetSharedEventParams({ club_id: data?.currentUser?.clubs?.[0]?.id });

  return (
    <pageContext.Provider value={{ navbarLeft }}>
      <div
        style={{
          display: "flex",
          flexFlow: navbarLeft ? "row nowrap" : "column nowrap",
          height: "100svh",
          width: "100svw",
          backgroundColor: COLOR_PRIMARY,
        }}
      >
        <Navbar />
        <div
          style={{
            flex: "1 1 0",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowX: "hidden",
              overflowY: "auto",
              backgroundColor: COLOR_WHITE,
              color: COLOR_BLACK,
              ...(isDarkMode && {
                backgroundColor: COLOR_BLACK,
                color: COLOR_WHITE,
              }),
            }}
          >
            <Stack noGap style={{ minHeight: "100%" }}>
              <Component {...params} />
            </Stack>
          </div>
        </div>
      </div>
    </pageContext.Provider>
  );
}
