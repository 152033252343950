import { gql } from "@apollo/client";
import ClipSvg from "assets/reactions/clip.svg";
import EVENT_TEMPLATES, { EVENT_DEFAULT_PARAMS, EVENT_PARAMS_KEYS } from "helpers/EVENT_TEMPLATES";
import { isEqual, pick } from "lodash-es";

export const EventTypeFilterFragment = gql`
  fragment EventTypeFilterFragment on Event {
    eventView {
      id
      wrapper
      points
      made
      reboundType
    }
  }
`;

const EVENT_TYPE_FILTERS = [
  {
    title: "All",
    shortName: "ALL",
    filter: () => true,
  },
  ...EVENT_TEMPLATES.map((template) => ({
    sport: template.sport,
    shortName: template.shortName,
    title: template.title,
    filter: (event) =>
      isEqual(
        {
          ...EVENT_DEFAULT_PARAMS,
          ...template.params,
        },
        pick(event.eventView, EVENT_PARAMS_KEYS),
      ),
  })),
  {
    title: "Reaction",
    filter: (event) => event.eventView.wrapper.endsWith("Reaction"),
    imgSrc: ClipSvg,
  },
];

export default EVENT_TYPE_FILTERS;
