import { gql } from "@apollo/client";
import { PageContentStack } from "atoms/Stack";
import { COLOR_ASSIST, COLOR_BLOCK, COLOR_POINT, COLOR_REBOUND, COLOR_STEAL } from "helpers/colors";
import useData from "helpers/useData";
import useEnforceAuth from "helpers/useEnforceAuth";
import useRouteState from "helpers/useRouteState";
import Section from "molecules/Section";
import StatsTable, { StatsTableCell, StatsTableHeaderCell, StatsTableRow } from "molecules/StatsTable";
import React from "react";
import { TeamPageTitle } from "shared/PageTitle";

import TeamPagesHeader from "./team/TeamPagesHeader";
import TeamSeasonSelectSubnav from "./team/TeamSeasonSelectSubnav";

export default function TeamPerformancePage({ teamId }) {
  useEnforceAuth({ enforceAuthenticated: true });

  const [seasonId, seasonIdSet] = useRouteState("seasonId");
  const [data] = useData(
    gql`
      query TeamPerformancePage($teamId: ID!, $seasonId: ID) {
        team(id: $teamId) {
          id
          name
          performanceSnapshotRows(seasonId: $seasonId) {
            title
            avgCell {
              value
              gamesCount
              people {
                id
                fullNameWithAnonymisation
              }
            }
            totalCell {
              value
              gamesCount
              people {
                id
                fullNameWithAnonymisation
              }
            }
            highCell {
              value
              people {
                id
                fullNameWithAnonymisation
              }
            }
          }
        }
      }
    `,
    { teamId, seasonId },
  );

  return (
    <>
      <TeamPageTitle title="Performance" />
      <TeamPagesHeader teamId={teamId} tab="performance" />
      <TeamSeasonSelectSubnav teamId={teamId} seasonId={seasonId} seasonIdSet={seasonIdSet} allowAllSeasons />
      <PageContentStack>
        <Section title="Performance Snapshot">
          <StatsTable headerColumns={[""]} columns={["Average", "Total", "Team High"]}>
            {data?.team.performanceSnapshotRows.map((row) => (
              <StatsTableRow key={row.title}>
                <StatsTableHeaderCell
                  large
                  color={
                    {
                      Points: COLOR_POINT,
                      Assists: COLOR_ASSIST,
                      Rebounds: COLOR_REBOUND,
                      Steals: COLOR_STEAL,
                      Blocks: COLOR_BLOCK,
                    }[row.title]
                  }
                >
                  {row.title}
                </StatsTableHeaderCell>
                <PerformanceSnapshotCell large cell={row.avgCell} />
                <PerformanceSnapshotCell large cell={row.totalCell} />
                <PerformanceSnapshotCell large cell={row.highCell} />
              </StatsTableRow>
            ))}
          </StatsTable>
        </Section>
      </PageContentStack>
    </>
  );
}

function PerformanceSnapshotCell({ cell }) {
  return (
    <StatsTableCell
      value={cell?.value || "-"}
      description={
        cell && (
          <>
            by {cell.people.map((p) => p.fullNameWithAnonymisation).join(" and ")} over {cell.gamesCount} games
          </>
        )
      }
    />
  );
}
