import { ONSEN_APP } from "helpers/environment";
import useFullscreenState from "helpers/useFullscreenState";
import { Fullscreen, FullscreenExit } from "mdi-material-ui";
import React from "react";

import PlayerButton from "./PlayerButton";

export default function FullscreenButton() {
  const [fullscreen, fullscreenSet] = useFullscreenState();

  if (ONSEN_APP) return null; // fullscreen on mobile is pointless
  return (
    <PlayerButton
      title="Fullscreen"
      onClick={() => fullscreenSet(!fullscreen)}
      icon={fullscreen ? <FullscreenExit /> : <Fullscreen />}
    />
  );
}
