import { gql } from "@apollo/client";
import { alpha, ButtonBase, ClickAwayListener, Paper, Popper } from "@mui/material";
import Avatar from "atoms/Avatar";
import Stack from "atoms/Stack";
import WithTooltip from "atoms/WithTooltip";
import { COLOR_PRIMARY_DARKER, COLOR_WHITE, getStatColor } from "helpers/colors";
import { FONT_FAMILY_LEAGUE_GOTHIC } from "helpers/FONT";
import { SPACING } from "helpers/spacings";
import { Filter } from "mdi-material-ui";
import { videoPageContext } from "pages/VideoPage";
import React, { useContext, useState } from "react";

export const VideoEventsFilterFragment = gql`
  fragment VideoEventsFilterFragment on Game {
    participations {
      id
      isHomeTeam
      team {
        id
        name
      }
      gamePlayers {
        id
        playerNumber
        person {
          id
          shortNameWithAnonymisation
        }
      }
    }
  }
`;

export default function VideoEventsFilter({ game }) {
  const {
    videoPageRef,
    filteredEventIds,
    sidebarRef,
    availableEventTypeFilters,
    activeEventTypeFilter,
    activeEventTypeFilterSet,
    homeFilterPersonId,
    homeFilterPersonIdSet,
    awayFilterPersonId,
    awayFilterPersonIdSet,
  } = useContext(videoPageContext);
  const [anchorEl, anchorElSet] = useState();

  return (
    <>
      <Stack
        data-video-joyride="filter-highlights"
        horizontal
        alignItemsCenter
        dense
        element={
          <ButtonBase
            focusRipple
            onClick={() => anchorElSet(anchorEl ? null : sidebarRef.current)}
            style={{
              textAlign: "start",
              borderBottom: `1px solid ${alpha(COLOR_WHITE, 0.1)}`,
            }}
          />
        }
        padding
      >
        <Filter />
        <Stack dense>
          <div style={{ fontWeight: "bold" }}>Filter Highlights</div>
          <div>
            Showing {filteredEventIds?.length} out {game?.events.length} of highlights
          </div>
        </Stack>
      </Stack>
      <Popper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="auto-start"
        style={{ zIndex: 1 }}
        popperOptions={{
          modifiers: [
            {
              name: "preventOverflow",
              options: {
                boundary: videoPageRef.current,
                mainAxis: true,
                altAxis: true,
              },
            },
          ],
        }}
      >
        <div
          style={{
            padding: SPACING,
          }}
        >
          <ClickAwayListener
            onClickAway={(event) => {
              event.preventDefault();
              event.stopImmediatePropagation();
              event.stopPropagation();
              anchorElSet(null);
            }}
          >
            <Stack
              component={Paper}
              style={{
                backgroundColor: COLOR_PRIMARY_DARKER,
                color: COLOR_WHITE,
              }}
              padding
            >
              <Stack horizontal dense style={{ maxWidth: 600 }}>
                {availableEventTypeFilters.map((eventTypeFilter) => (
                  <Avatar
                    key={eventTypeFilter.title}
                    style={{
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    size={32}
                    inverse
                    color={getStatColor(eventTypeFilter.title)}
                    active={activeEventTypeFilter === eventTypeFilter}
                    imgSrc={eventTypeFilter.imgSrc}
                    text={eventTypeFilter.shortName}
                    element={
                      <WithTooltip
                        tooltip={eventTypeFilter.title}
                        inverse
                        element={<ButtonBase focusRipple onClick={() => activeEventTypeFilterSet(eventTypeFilter)} />}
                      />
                    }
                  />
                ))}
              </Stack>
              <Stack horizontal>
                {game?.participations
                  .map((participation) => [
                    participation,
                    participation.isHomeTeam ? homeFilterPersonId : awayFilterPersonId,
                    participation.isHomeTeam ? homeFilterPersonIdSet : awayFilterPersonIdSet,
                  ])
                  .map(([participation, filterPersonId, filterPersonIdSet]) => (
                    <Stack
                      key={participation.id}
                      style={{
                        flex: "1 1 auto",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "2em",
                          textTransform: "uppercase",
                          fontFamily: FONT_FAMILY_LEAGUE_GOTHIC,
                        }}
                      >
                        {participation.team.name}
                      </div>
                      <div
                        style={{
                          flex: "1 1 auto",
                          position: "relative",
                          height: 200,
                          overflow: "auto",
                        }}
                      >
                        <Stack
                          dense
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          {[
                            ["ALL", "All"],
                            [null, "None"],
                            ...participation.gamePlayers.map((gamePlayer) => [
                              gamePlayer.person.id,
                              <>
                                {gamePlayer.person.shortNameWithAnonymisation} #{gamePlayer.playerNumber}
                              </>,
                            ]),
                          ].map(([personId, label]) => (
                            <div
                              key={personId}
                              onClick={() => filterPersonIdSet(personId)}
                              style={{
                                fontWeight: "bold",
                                opacity: personId === filterPersonId ? 1 : 0.5,
                                textAlign: "start",
                                cursor: "pointer",
                                tabIndex: 0,
                              }}
                            >
                              {label}
                            </div>
                          ))}
                        </Stack>
                      </div>
                    </Stack>
                  ))}
              </Stack>
            </Stack>
          </ClickAwayListener>
        </div>
      </Popper>
    </>
  );
}
